'use client';

import React from 'react';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { Flex, Input, Skeleton, Typography } from 'antd';
import { fontVariants } from '@shared/constants/fontVariants';
import { IProfile } from '@widgets/mediakit/MediaKit/Profile';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';

const Container = styled('div')({
  gap: '1rem',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const BioSkeleton = styled(Skeleton)({
  [`&.${prefixCls}-skeleton`]: {
    [`& > .${prefixCls}-skeleton-content`]: {
      [`& > .${prefixCls}-skeleton-paragraph`]: {
        marginBottom: 0,
        '& > li + li': {
          marginTop: '.8rem',
        },
      },
    },
  },
});

const More = styled('span')({
  color: 'rgba(0, 0, 0, 0.45)',
  transition: 'color 0.3s ease',
  '&:hover': {
    color: 'rgba(0, 0, 0, 0.75)',
  },
});

export const BioInput: React.FC<{
  isEditing?: boolean;
  bio?: IProfile['bio'];
  showSkeleton?: boolean;
  setProfileData: React.Dispatch<React.SetStateAction<IProfile>>;
}> = (props) => {
  let { isEditing, showSkeleton, bio, setProfileData } = props;
  bio = bio?.trim() || '';
  const posthog = useLocalPostHog();

  const [expanded, onExpanded] = React.useState(false);

  const onBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value?.trim() || '';

    if (value) {
      try {
        posthog?.capture(PosthogEvents.AddedBio);
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setProfileData((prevState) => {
      return {
        ...prevState,
        bio: e.currentTarget.value,
      };
    });
  };

  if (showSkeleton) {
    return (
      <BioSkeleton
        title={false}
        active={showSkeleton}
        loading={showSkeleton}
        paragraph={{ rows: 3 }}
      />
    );
  }

  if (isEditing) {
    return (
      <Container>
        <Flex>
          <Typography.Text strong>Bio</Typography.Text>
        </Flex>
        <Input.TextArea
          size="large"
          onBlur={onBlur}
          id="bioTextAreaTarget"
          defaultValue={bio || ''}
          onChange={handleOnChange}
          placeholder="Your bio..."
          style={{ resize: 'none' }}
          autoSize={{ minRows: 2, maxRows: 5 }}
          className={`${fontVariants.opt3} animate__animated`}
        />
      </Container>
    );
  }

  return bio ? (
    <div>
      <Typography.Paragraph
        style={{ textAlign: 'start' }}
        ellipsis={{
          rows: 3,
          expanded,
          expandable: true,
          symbol: <More>more</More>,
          onExpand: (_e, info) => {
            onExpanded(info.expanded);

            try {
              posthog?.capture(PosthogEvents.bio_show_more);
            } catch (e) {
              console.error('Posthog error:', e);
            }
          },
        }}
        onClick={() => {
          onExpanded(true);
          try {
            posthog?.capture(PosthogEvents.bio_show_more, { bioText: true });
          } catch (e) {
            console.error('Posthog error:', e);
          }
        }}
      >
        {bio}
      </Typography.Paragraph>
    </div>
  ) : null;
};
