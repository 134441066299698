import React from 'react';
import { Builder } from '@shared/types/builder';
import { ComponentNames } from '@shared/constants/componentNames';
import * as Demo from '@widgets/mediakit/components-drawer/components/DemoComponents/Basic';

export const componentsMap: Record<
  string,
  (props: Builder.DemoComponentProps) => React.JSX.Element
> = {
  [ComponentNames.basics_link]: Demo.LinkDemo,
  [ComponentNames.basics_text]: Demo.CommonDemo,
  [ComponentNames.basics_image]: Demo.ImageDemo,
  [ComponentNames.basics_video]: Demo.VideoDemo,
  [ComponentNames.basics_title]: Demo.CommonDemo,
  [ComponentNames.basics_gallery]: Demo.CommonDemo,
  [ComponentNames.basics_info_card]: Demo.CommonDemo,
  [ComponentNames.basics_service_list]: Demo.ServiceList,
};

export const orderedComponentMap = [
  ComponentNames.basics_image,
  ComponentNames.basics_link,
  ComponentNames.basics_service_list,
  ComponentNames.basics_info_card,
  ComponentNames.basics_text,
  ComponentNames.basics_title,
  ComponentNames.basics_video,
  ComponentNames.basics_gallery,
];
